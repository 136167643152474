const getRandomKey = function(){
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // after the decimal.
  return '_' + Math.random().toString(36).substr(2, 9);
};

const getUA = function(){
  
  let _ua = navigator.userAgent.toLowerCase();
  let _o = {
    ltIE6:typeof window.addEventListener == "undefined" && typeof document.documentElement.style.maxHeight == "undefined",
    ltIE7:typeof window.addEventListener == "undefined" && typeof document.querySelectorAll == "undefined",
    ltIE8:typeof window.addEventListener == "undefined" && typeof document.getElementsByClassName == "undefined",
    IE9: navigator.appVersion.toLowerCase().indexOf("msie 9.") != -1,
    IE10 : navigator.userAgent.match(/Trident\/[6]/i),
    IE11: navigator.userAgent.match(/Trident\/7\./),
    IE:document.uniqueID,
    Firefox:window.sidebar,
    Opera:window.opera,
    chrome: _ua.indexOf('chrome') > -1,
    //Webkit:!document.uniqueID && !window.opera && !window.sidebar && !window.orientation && window.localStorage,
    mobile:/android|iphone|ipad|ipod/i.test(navigator.userAgent.toLowerCase()),
    iphone:/iphone|ipod/i.test(_ua),
    android:/android/.test(_ua),
    ipad:/ipad/.test(_ua),
    tablet:undefined,
    smartphone:undefined,
    touch: window.ontouchstart === null
  }
  _o.tablet = _o.ipad;
  if(!_o.tablet && _o.android){ _o.tablet = !(/mobile/.test(_ua));}				
  _o.smartphone = _o.iphone || _o.android ? true : false;
  
  let v = [];
  if (/iP(hone|od|ad)/.test(navigator.platform)) {v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);}
  let vAry = [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
  _o.iosV = vAry[0] ? vAry[0] : '';

  return _o;
  
}

class modalWindow {

	isOpen = false;
  $win = $(window);
	modalwrapper;
	modalContainer;
	modalContents;	
	contents;
	optionsDefault = {url: null, resetContent: true, iscroll: true, fullscreen: false, align: 'top', marginTop: 0, speed: 1000, style:{backgroundColor: '#000'}}
	options;
	onUpdateCallback;
	spMyScroll;
  ua = {};

	constructor (options = {}) {

		const key = options.modalID ? options.modalID : 'modal' + getRandomKey();
		const html = '<div id="'+ key +'" class="modal">'+
			'<div class="modal__container">'+
				'<div class="modal__contents">'+
				'</div>'+
			'</div>'+
		'</div>';

		//alert(key)
		$('body').append(html);
		this.modalwrapper = $('#' + key);
		this.modalContainer = this.modalwrapper.find('.modal__container');
		this.modalContents = this.modalwrapper.find('.modal__contents');
		this.ua = getUA();

		//)

		if(options.content){
			this.contents = $(options.content);
			$(this.contents).appendTo(this.modalContents);
		}

	}

	setContents(data, param){
		let obj = param;
		this.options = $.extend({}, this.optionsDefault, obj);
		
		if(data)
		this.contents = data;

	}

	toggle (obj){

		let w = this.$win.width();
		let h = this.$win.height();

		let callbacks = {
			beforeOpen : ()=>{},
			beforeClose : ()=>{},
			onOpen : ()=>{},
			onClose : ()=>{}
		}

		
		callbacks = $.extend({}, callbacks, obj);
		

		let callbackbeforeOpen = callbacks.beforeOpen;
		let callbackbeforeClose = callbacks.beforeClose;
		let callbackOnOpen = callbacks.onOpen;
		let callbackOnClose = callbacks.onClose;

		if(!this.isOpen){

			let windowTop = this.$win.scrollTop();

			$('body').css({
				position: 'fixed',
				left: 0,
				top: windowTop,
				width: '100%'
			});

			$('.close-modal-button', this.modalContainer).on('click', (e)=>{
				e.preventDefault();
				this.toggle(callbackOnOpen);
			});
			
			this.$win.on('resize.modalWindow load.modalWindow', ()=>{
				this.resize();
			});
		
      if(this.options.resetContent == true){
			  this.modalContents.append(this.contents);
      }

			
			this.modalwrapper.attr('style', '');
			this.modalwrapper.css({width: w, height: h, top: 0, left: 0, opacity: 0}).addClass('modal-style-01').css(this.options.style).show();
      
			this.modalwrapper.stop().animate({
				opacity: 1
			}, this.options.speed, 'easeOutCubic');

			this.modalContainer.css({opacity: 0, top: 0, left: 0}).show();
			callbackbeforeOpen();
      this.modalContainer.scrollTop(0);

			this.modalContainer.stop().animate({
				opacity: 1
			}, this.options.speed, 'easeOutCubic', ()=>{
				
				callbackOnOpen();
        
				if(this.options.iscroll && this.ua.smartphone){
					
					// $('> *', this.modalContents).wrap('<div id="modal-iscroll-modalContainer"><div id="modal-iscroll-modalContainer-inner"></div></div>');
					// $('#modal-iscroll-modalContainer').css({height: '80vh', overflow: 'hidden'});
					// //$('#modal-iscroll-modalContainer-inner').css({position: 'absolute', left: 0, top: 0, width: '100%'});
					// $('#modal-iscroll-modalContainer').imagesLoaded(function(){
					// 	this.spMyScroll = new BScroll('#modal-iscroll-modalContainer', { mouseWheel: true, click: true });
					// 	//this.spMyScroll.refresh();
					// });

				}		

			});
		
			this.modalContainer.on('click', (e)=>{		
				if($(e.target).hasClass('modal__container') == true || $(e.target).hasClass('modal__contents') == true)
				this.toggle(callbacks);
			});
			
			this.modalContainer.find('.close-modal-button').on('click tap', (e)=>{
				e.preventDefault();
				this.toggle(callbacks);
			});
				
		
					
			this.isOpen = true;
			this.resize();
			
		} else {
		
			callbackbeforeClose();

			this.modalwrapper.stop().fadeOut(this.options.speed, 'easeOutCubic');			
			this.modalContainer.stop().fadeOut(this.options.speed, 'easeOutCubic', ()=>{
				this.modalwrapper.removeClass('modal-style-01');

        if(this.options.resetContent == true){
				  this.modalContents.html('');
        }

				if(this.options.resetContent == true){
					this.contents = null;	
				}

				this.isOpen = false;
				this.isOpen = null;
				this.options =  null;
				
				this.modalwrapper.hide();
				this.modalContainer.hide();


			});			
			
			this.modalContainer.off('click');
			$('.close-modal-button').off('click');
			
			this.onUpdateCallback = null;
			
			setTimeout(function(){
				callbackOnClose();
			}, this.options.speed);		
			
			let currentTop = $('body').css('top').replace('px', '');

			$('body').css({
				position: 'static',
				left: 0,
				top: 0,
				width: 'auto'
			});

			this.$win.scrollTop(currentTop); 
			
			this.$win.off('resize.modalWindow load.modalWindow');	

		}
	}

	scrollTo(target){
		this.modalContainer.scrollTop(target);
	}

	resize(){
		
		let w = this.$win.width();
		let h = this.$win.height();
		let _self = this;

		if(this.isOpen){
			var diff = this.ua.iphone ? 100 : 0;
			this.modalwrapper.css({ width: w, height: h + diff});
			this.modalContainer.css({width: w, height: h + diff});	
			
			if(this.options.align == 'center'){

				setTimeout(function(){
					var contH = _self.contents.outerHeight();
					var contY = (h - contH) / 2;
					var contW = _self.contents.outerWidth();
					var contX = (w - contW) / 2;	
					_self.modalContents.css({marginTop: Math.max(contY, 10)});
				}, 10);

			} else {
				this.modalContents.css({marginTop: this.options.marginTop});
			}
			
      if(this.contents){
        if(this.contents.height() < w){
          this.modalContainer.css({overflowY: 'auto'});
        } else {
          this.modalContainer.css({overflowY: 'scroll'});
        }
      }
		}

	}

}



(()=>{

	// $(window).load(function(){
	// 	$('html,body').animate({ scrollTop: 0 }, '1');
	// });

	const $doc = $(document);
	const $win = $(window);
	let docHeight = 0;
	let winHeight = 0;
	let vh = 0;



	const pageScrollTo = (tar, e) => {

		if(e)
		e.preventDefault();

		tar = tar.replace('#', '');

		if($('[data-id="' + tar + '"]').length){
			let diff = $('.toggle-nav-button').is(':visible') ? 60 : 144;
			let pos = tar == '#header' ? 0 : $('[data-id="'+tar+'"]').offset().top - diff;
			$('html,body').stop().animate({ scrollTop: pos }, 1000, 'easeOutExpo');
		}

	}

	const resizeHandler = ()=>{
		
		docHeight = $doc.height();
		winHeight = $win.height();

		vh = window.innerHeight * 0.01;
		// Then we set the value in the --vh custom property to the root of the document
		document.documentElement.style.setProperty('--vh', `${vh}px`);		

	}

	$('.hero-section .hero-image *').addClass('executed');


	$('.anchor').off('click').on('click', (e)=>{
		e.preventDefault();
		let tgt = $(e.currentTarget).attr('href').replace('#', '');
		pageScrollTo(tgt, e)
	});
	
	$('.box-header').matchHeight();
	$('.box-content').matchHeight();

	resizeHandler();

	$win.on('load resize', resizeHandler);

	$('.toggle-nav-button').on('click', ()=>{
		menu.toggle();
	});

	$('.hamburger-button').on("click", function() {
		$('.header-nav').slideToggle();
		$('.header-nav').toggleClass("open");
		$(this).toggleClass("opened");
	});

	$('.toggle-bar').on("click", function() {
		$(this).next().slideToggle();
		$(this).toggleClass("open");
	});

	$('.header-nav a').on('click', (e)=>{
		if(menu.menuOpen){
			menu.close();
		}
	});		

	$('.hero-section .fadein').addClass('executed');

		let st = 0;

		$win.on('scroll.home', function (){
			st = $win.scrollTop();	

			//$('.hero-section').css({transform: 'translateY(-' + Math.round(st * speed) + 'px)'});
			// if(st > 100){
			// 	$('.scroll-icon').addClass('hidden');
			// } else {
			// 	$('.scroll-icon').removeClass('hidden');
			// }

			//let n = 0;
			$('.prlx').each((i, elem) => {
				let thisY = $(elem).offset().top - ($(elem).height() / 2);
				let ratio =  0.8;

				if(thisY <= (st + (winHeight*ratio))){

					$(elem).addClass('executed');

				}
			
			});


			if(st >= docHeight - winHeight + 100){
				$('.prlx').addClass('executed');
			}

		});

		$win.trigger('scroll.home');

		setTimeout(()=>{
			$('body').removeClass('no-scroll');
		}, 2000);


		if($('.main-section .hero-image-wrapper .swiper-slide').length > 1){
			const home_slider = new Swiper('.hero-image-wrapper .swiper-container', {
				speed: 1000,
				effect: 'fade',
				preventInteractionOnTransition: true,
				loop: true,
				noSwiping: true,
				allowTouchMove: false,
				fadeEffect: {
					crossFade: true
				},
				autoplay: {
					delay: 4000,
					disableOnInteraction: true
				}				
			});
			
			$('.main-section .carousel-slider button').on('click', (e)=>{
				const index = $(e.currentTarget).parent().index();
				home_slider.slideTo(index);
			});
		}		

		if($('.thumbnail-list li').length > 1){
			const slider = new Swiper('.swiper-container', {
				effect: 'fade',
				fadeEffect: {
					crossFade: true
				}
			});
			
			$('.thumbnail-list li button').on('click', (e)=>{
				const index = $(e.currentTarget).parent().index();
				slider.slideTo(index);
			});
		}

		if($('.main-section .carousel-slider .swiper-slide').length > 1){
			const home_slider = new Swiper('.carousel-slider .swiper-container', {
				speed: 500,
				effect: 'fade',
				fadeEffect: {
					crossFade: true
				},
				autoplay: {
					delay: 5000,
				}				
			});
			
			$('.main-section .carousel-slider button').on('click', (e)=>{
				const index = $(e.currentTarget).parent().index();
				home_slider.slideTo(index);
			});
		}

		if($('.main-section .model-homes-slider .swiper-slide').length > 1){
		
			const home_slider = new Swiper('.model-homes-slider', {
				speed: 500,
				slidesPerView: 2,
				spaceBetween: 16,
				loopAdditionalSlides: 4,
				loop: true,
				pagination: {
					el: '.swiper-pagination',
					clickable: true,
				},
				autoplay: {
					delay: 5000,
				},
				breakpoints: {
					// when window width is >= 320px
					600: {
						slidesPerView: 3
					},
				}							
			});
			
			$('.main-section .carousel-slider button').on('click', (e)=>{
				const index = $(e.currentTarget).parent().index();
				home_slider.slideTo(index);
			});
		}	


		const modal = new modalWindow({content: '.tel-info'});
		
		$('.open-tel-info-button').on('click', (e)=>{
			e.preventDefault();
			modal.setContents(null, {resetContent: false, iscroll: true, align: 'center', marginTop: 0, speed: 600, style: {backgroundColor: 'rgba(0,0,0,0.7)'}});		
			modal.toggle();	
		});

})();




const menu = {

	menuOpen: false,
	timer: null,

	open(){

		this.menuOpen = true;

		$('.site-nav').show();

		if(this.timer) clearTimeout(this.timer);
		this.timer = setTimeout(
			()=>{				
				$('.site-nav').addClass('visible');
				$('.toggle-nav-button').addClass('opened');
			}, 100);	

	},

	close(){

		this.menuOpen = false;
		
		$('.site-nav').removeClass('visible');
		$('.toggle-nav-button').removeClass('opened');
		
		if(this.timer) clearTimeout(this.timer);
		this.timer = setTimeout(
			()=>{
				$('.site-nav').hide();
			}, 300);
		
	},

	toggle(){
		if(this.menuOpen){
			this.close();
		} else {
			this.open();
		}
	}	

}

